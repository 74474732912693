<template>
  <h2>Private HomePage</h2>

</template>

<script lang="ts" setup>



</script>

<style scoped>

</style>
