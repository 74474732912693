export const cs = {
    "startsWith": "Začíná na",
    "contains": "Obsahuje",
    "notContains": "Neobsahuje",
    "endsWith": "Končí na",
    "equals": "Rovná se",
    "notEquals": "Nerovná se",
    "noFilter": "Bez filtru",
    "filter": "Filtr",
    "lt": "Menší než",
    "lte": "Menší než nebo rovno",
    "gt": "Větší než",
    "gte": "Větší než nebo rovno",
    "dateIs": "Datum je",
    "dateIsNot": "Datum není",
    "dateBefore": "Datum je před",
    "dateAfter": "Datum je po",
    "custom": "Vlastní",
    "clear": "Vyčistit",
    "apply": "Použít",
    "matchAll": "Odpovídá všem",
    "matchAny": "Odpovídá jakémukoli",
    "addRule": "Přidat pravidlo",
    "removeRule": "Odstranit pravidlo",
    "accept": "Ano",
    "reject": "Ne",
    "choose": "Vybrat",
    "upload": "Nahrát",
    "cancel": "Zrušit",
    "completed": "Dokončeno",
    "pending": "Čekající",
    "dayNames": [
        "Neděle",
        "Pondělí",
        "Úterý",
        "Středa",
        "Čtvrtek",
        "Pátek",
        "Sobota"
    ],
    "dayNamesShort": ["Ned", "Pon", "Úte", "Stř", "Čtv", "Pát", "Sob"],
    "dayNamesMin": ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
    "monthNames": [
        "Leden",
        "Únor",
        "Březen",
        "Duben",
        "Květen",
        "Červen",
        "Červenec",
        "Srpen",
        "Září",
        "Říjen",
        "Listopad",
        "Prosinec"
    ],
    "monthNamesShort": [
        "Led",
        "Úno",
        "Bře",
        "Dub",
        "Kvě",
        "Čer",
        "Čvc",
        "Srp",
        "Zář",
        "Říj",
        "Lis",
        "Pro"
    ],
    "chooseYear": "Vyberte rok",
    "chooseMonth": "Vyberte měsíc",
    "chooseDate": "Vyberte datum",
    "prevDecade": "Předchozí desetiletí",
    "nextDecade": "Následující desetiletí",
    "prevYear": "Předchozí rok",
    "nextYear": "Následující rok",
    "prevMonth": "Předchozí měsíc",
    "nextMonth": "Následující měsíc",
    "prevHour": "Předchozí hodina",
    "nextHour": "Následující hodina",
    "prevMinute": "Předchozí minuta",
    "nextMinute": "Následující minuta",
    "prevSecond": "Předchozí sekunda",
    "nextSecond": "Následující sekunda",
    "am": "dopoledne",
    "pm": "odpoledne",
    "today": "Dnes",
    "weekHeader": "Týd.",
    "firstDayOfWeek": 1,
    "showMonthAfterYear": false,
    "dateFormat": "dd.mm.yy",
    "weak": "Slabé",
    "medium": "Střední",
    "strong": "Silné",
    "passwordPrompt": "Zadejte heslo",
    "emptyFilterMessage": "Nebyly nalezeny žádné výsledky",
    "searchMessage": "Je k dispozici {0} výsledků",
    "selectionMessage": "Vybráno {0} položek",
    "emptySelectionMessage": "Žádná vybraná položka",
    "emptySearchMessage": "Nebyly nalezeny žádné výsledky",
    "emptyMessage": "Žádné dostupné možnosti",
    "aria": {
        "trueLabel": "Pravda",
        "falseLabel": "Nepravda",
        "nullLabel": "Nevybráno",
        "star": "1 hvězda",
        "stars": "{star} hvězd",
        "selectAll": "Všechny položky vybrány",
        "unselectAll": "Všechny položky zrušeny",
        "close": "Zavřít",
        "previous": "Předchozí",
        "next": "Další",
        "navigation": "Navigace",
        "scrollTop": "Posunout nahoru",
        "moveTop": "Přesunout nahoru",
        "moveUp": "Přesunout nahoru",
        "moveDown": "Přesunout dolů",
        "moveBottom": "Přesunout dolů",
        "moveToTarget": "Přesunout na cíl",
        "moveToSource": "Přesunout ke zdroji",
        "moveAllToTarget": "Přesunout vše na cíl",
        "moveAllToSource": "Přesunout vše ke zdroji",
        "pageLabel": "{page}",
        "firstPageLabel": "První strana",
        "lastPageLabel": "Poslední strana",
        "nextPageLabel": "Další strana",
        "previousPageLabel": "Předchozí strana",
        "rowsPerPageLabel": "Řádků na stranu",
        "jumpToPageDropdownLabel": "Přejít na stránku Dropdown",
        "jumpToPageInputLabel": "Přejít na stránku Input",
        "selectRow": "Vybrat řádek",
        "unselectRow": "Zrušit výběr řádku",
        "expandRow": "Rozbalit řádek",
        "collapseRow": "Sbalit řádek",
        "showFilterMenu": "Zobrazit filtr menu",
        "hideFilterMenu": "Skrýt filtr menu",
        "filterOperator": "Operátor filtru",
        "filterConstraint": "Omezení filtru",
        "editRow": "Upravit řádek",
        "saveEdit": "Uložit úpravu",
        "cancelEdit": "Zrušit úpravu",
        "listView": "Zobrazení seznamu",
        "gridView": "Zobrazení mřížky",
        "slide": "Snímek",
        "slideNumber": "{slideNumber}",
        "zoomImage": "Přiblížit obrázek",
        "zoomIn": "Přiblížit",
        "zoomOut": "Oddálit",
        "rotateRight": "Otočit doprava",
        "rotateLeft": "Otočit doleva"
    }
};
